import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {NumberInput, Table, TableBody, TableCell, TableHead, TableRow, ToggleTab, ToggleTabSwitch, useStyles} from '@ace-de/ui-components';
import {apsServiceTypes, apsMemberBudgetTariffTypes} from '@ace-de/eua-entity-types';
import memberStatuses from '../memberStatuses';

const ServicesTable = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('budgets_screen');
    const {isEdit, onChange, serviceBudgets, formData} = props;
    const [selectedBudgetTariff, setSelectedBudgetTariff] = useState(apsMemberBudgetTariffTypes.CLASSIC);

    const handleOnChange = (tariff, service, memberStatus, value) => {
        if (typeof onChange !== 'function') return;

        onChange(prevState => ({
            ...prevState,
            [tariff]: {
                ...(prevState[tariff]),
                [service]: {
                    ...(prevState[tariff]?.[service]),
                    [memberStatus]: {
                        ...(prevState[tariff]?.[service]?.[memberStatus]),
                        value,
                    },
                },
            },
        }));
    };

    if (!serviceBudgets) return null;

    return (
        <div>
            <ToggleTabSwitch
                name="budgetTariff"
                className={cx('global!ace-u-margin--bottom-24')}
                onChange={value => setSelectedBudgetTariff(value)}
                value={selectedBudgetTariff}
            >
                {Object.values(apsMemberBudgetTariffTypes).map(tab => (
                    <ToggleTab
                        key={tab}
                        name={`${tab.toLowerCase()}Tab`}
                        value={tab}
                    >
                        {translateScreen(`budget_tariff.${tab.toLowerCase()}`)}
                    </ToggleTab>
                ))}
            </ToggleTabSwitch>
            <Table className={cx('global!ace-u-margin--top-24')}>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={3}>
                            {translateScreen('table_header.service')}
                        </TableCell>
                        {memberStatuses.map(status => (
                            <TableCell
                                key={status}
                                colSpan={2}
                            >
                                {translateScreen(`table_header.member_status_${status.toLowerCase()}`)}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.values(apsServiceTypes).filter(serviceType => (
                        // for now we're excluding all service types that are not on BE,
                        // as they're not part of the story https://computerrock.atlassian.net/browse/ACELEA-3077
                        [apsServiceTypes.SHORT_TRIP,
                            apsServiceTypes.ACCOMMODATION,
                            apsServiceTypes.RENTAL_CAR__DOMESTIC_DAILY,
                            apsServiceTypes.RENTAL_CAR__ABROAD_MAX,
                            apsServiceTypes.RENTAL_CAR__ABROAD_HOME_MAX].includes(serviceType)
                    )).map(service => (
                        <TableRow key={service}>
                            <TableCell colSpan={3}>
                                {translateScreen(`table_cell.service_${service.toLowerCase()}`)}
                            </TableCell>
                            {!isEdit
                                ? !!serviceBudgets[selectedBudgetTariff]?.[service]
                                && memberStatuses.map(memberStatus => (
                                    <TableCell
                                        key={`${service}-${memberStatus}`}
                                        colSpan={2}
                                    >
                                        {serviceBudgets[selectedBudgetTariff][service][memberStatus]?.value}
                                    </TableCell>
                                ))
                                : memberStatuses.map(status => (
                                    <TableCell colSpan={2} key={`${service}-${status}`}>
                                        <NumberInput
                                            name={`${service}-${status}`}
                                            className={cx([
                                                'global!ace-u-width--full',
                                                'ace-c-number-input--small',
                                            ])}
                                            value={formData?.[selectedBudgetTariff]?.[service]?.[status]?.value || 0}
                                            onChange={value => {
                                                handleOnChange(selectedBudgetTariff, service, status, value);
                                            }}
                                        />
                                    </TableCell>
                                ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

ServicesTable.propTypes = {
    isEdit: PropTypes.bool,
    onChange: PropTypes.func,
    serviceBudgets: PropTypes.object,
    formData: PropTypes.object,
};

ServicesTable.defaultProps = {
    isEdit: false,
    onChange: null,
    serviceBudgets: null,
    formData: null,
};

const mapStateToProps = state => ({
    serviceBudgets: state.budgets.serviceBudgets,
});

export default connect(mapStateToProps)(ServicesTable);
